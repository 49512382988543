<template>
    <div class="w-100 pl-4 mb-4">
        <v-card outlined>
            <v-card-text>
                <p class="display-1 text--primary" v-html="$t(`${name.toLowerCase()}.heading`)"></p>
                <p v-html="$tc(`${name.toLowerCase()}.subheading`, getProperties.length, {memberid: getActingMemberid})"></p>
            </v-card-text>
            <v-row>
                <v-col class="pl-6 pr-6">
                    <search
                        :search-value="search"
                        adjutable
                        ref="searchComponent"
                        @emit-switch-type="emitSwitchType"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="properties"
                        :search="search"
                        :custom-filter="customFilter"
                        :loading="$store.getters.getLoaders.properties"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                        @click:row="clickRow"
                    >
                        <template v-slot:item.obj_sortimages="{ item }">
                            <v-avatar
                                v-if="item.obj_sortimages"
                                size="35"
                            >
                                <v-img
                                    :src="getImage(item)"
                                    :lazy-src="getImage(item)"
                                    :alt="'Main image of ' + item.dynamic_title"
                                />
                            </v-avatar>
                            <v-avatar
                                v-else
                                size="35"
                            >
                                <v-icon :color="$store.getters.getColors.secondary1">
                                    mdi-image-area
                                </v-icon>
                            </v-avatar>
                        </template>
                        <template v-slot:item.obj_aktiv="{ item }">
                            <v-icon x-small :color="(item.obj_aktiv ? $store.getters.getColors.success : $store.getters.getColors.error)">
                                mdi-checkbox-blank-circle
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Search from '@/components/Allgemein/Suche.vue'
import { mapGetters } from 'vuex'
import PropertiesService from "@/services/properties.service";

const name = 'Properties'

export default {
    name: name,
    components: { Search },
    metaInfo: {
        title: name,
    },
    data() {
        return {
            name: name,
            properties: [],
            customFilterEnabled: (this.$cookies.get('searchType') === 'true'),
            expanded: [],
            singleExpand: false,
            search: '',
        }
    },
    sockets: {
        async propertyUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getProperties()
                await this.bannerMessageForSocketEvent('property', data.memberid)
            }
        },
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
        ]),
        headers: function () {
            return [
                {
                    text: '',
                    value: 'obj_sortimages',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '50px',
                },
                {
                    text: this.$i18n.t('common.labels.id'),
                    value: 'obj_id',
                    align: 'end',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.labels.numberShorthand'),
                    value: 'obj_nummer',
                    align: 'end',
                    divider: true,
                    width: '100px',
                },
                {
                    text: this.$i18n.t('common.labels.title'),
                    value: 'dynamic_title',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.active'),
                    value: 'obj_aktiv',
                    align: 'center',
                    divider: true,
                    width: '90px',
                },
            ]
        },
    },
    methods: {
        async bannerMessageForSocketEvent(dataType, memberid) {
            await this.$store.dispatch('socketEventBanner', {
                message: `Some ${ dataType } changed for member ${ memberid }!`,
                autoHide: true,
            })
        },
        emitSwitchType(value) {
            this.customFilterEnabled = value
        },
        customFilter(value, search, item) {

            let test = false

            if (!this.customFilterEnabled) {
                //Info: check only numeric search values
                if (/(\d){1,45}|(\s)?/.test(search)) {
                    test = item.obj_id.toString().includes(search.toString()) || item.obj_nummer.toString().includes(search.toString())

                    if (/\s/g.test(search))
                        test = item.obj_id.toString() === search.toString() || item.obj_nummer.toString() === search.toString()
                }

                //Info: check only alpha numeric search values
                if (/([a-zA-z])/.test(search))
                    test = item.hasOwnProperty('obj_titel') && item.obj_titel.toString().toLowerCase().includes(search.toString().toLowerCase())
            }
            else
                test = (item.obj_id.toString().includes(search.toString()) || (item.obj_nummer.toString().includes(search.toString())) || item.hasOwnProperty('obj_titel') && item.obj_titel.toString().toLowerCase().includes(search.toString().toLowerCase()))

            return test
        },
        clickRow(item) {
            this.$router.push({ path: `/property/${ item.obj_id }` })
        },
        getImage(property) {
            if (property.obj_sortimages) {
                const md5 = property.obj_sortimages.split(',')[0]
                return `https://api.feondi.de/common/image.php?u=xsigns&p=sfgashfasdfajshbfkas&art=objekt&memberid=${ this.getActingMemberid }&entityid=${ property.obj_id }&md5=${ md5 }`
            }

            return ''
        },
        async getProperties() {
            await this.$store.dispatch('setLoaders', { properties: true })
            this.properties = await PropertiesService.getPropertiesByMember({ memberid: this.$store.getters.getActingMemberid })
            await this.$store.dispatch('setLoaders', { properties: false })
        }
    },
    async created() {
        await this.getProperties()
    },
}
</script>
