import communicate from '@/services/communicate.service'

export default {
    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertiesByMember(payload) {
        return await communicate.request(enums.GET, 'properties/' + payload.memberid)
    },

    async getPropertyByMemberAndPropertyId(payload) {
        return await communicate.request(enums.GET, 'property/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyImages(payload) {
        return await communicate.request(enums.GET, 'property/images/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyPrices(payload) {
        return await communicate.request(enums.GET, 'property/prices/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertySurcharges(payload) {
        return await communicate.request(enums.GET, 'property/surcharges/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyOccupancies(payload) {
        return await communicate.request(enums.GET, 'property/occupancies/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyOccupancy(payload) {
        return await communicate.request(enums.GET, '/property/occupancy/' + payload.memberid + '/' + payload.objid + '/' + payload.occupancytype + '/' + payload.occupancyid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyServices(payload) {
        return await communicate.request(enums.GET, 'property/services/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyDistances(payload) {
        return await communicate.request(enums.GET, 'property/distances/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyAmenities(payload) {
        return await communicate.request(enums.GET, 'property/amenities/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyOffers(payload) {
        return await communicate.request(enums.GET, 'property/offers/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyDiscounts(payload) {
        return await communicate.request(enums.GET, 'property/discounts/' + payload.memberid + '/' + payload.objid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getPropertyRooms(payload) {
        return await communicate.request(enums.GET, 'property/rooms/' + payload.memberid + '/' + payload.objid)
    },
}